import React, { useCallback } from "react"
import { graphql } from "gatsby"
import { window } from "browser-monads"
import cx from "classnames"

import Layout from "../components/layout"
import Author from "../components/author/author"
import RichText from "../components/richText/richText"
import Sidebar from "../components/sidebar/sidebar"
import Seo from "../components/seo/seo"
import Section from "../components/section/section"
import CtaEduWork from "../components/ctaEduWork/ctaEduWork"
import Cta from "../components/cta/cta"
import BlogSocialShare from "../components/blogPostSocialShare/blogPostSocialShare"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"

import { useTranslation } from "../helpers/useTranslation"
import { findAvailableModule } from "../helpers/findAvailableModule"

import * as styles from "./blog-post.module.scss"

// It's imported this way because it's also used in gatsby-node
const generatePath = require("../helpers/generatePath")

const BlogPost = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { content, seo, cta } = data.sanityBlogPost.tabs
  const categories = data.allSanityBlogCategory
  const listing = data.sanityBlogListing.tabs.content
  const form = listing.subscribeForm
  const translations = data.sanityTranslationBlogPost?.translation
  const enPathUrl = translations && generatePath(translations[0])
  const esPathUrl = translations && generatePath(translations[1])
  // const auPathUrl = translations && generatePath(translations[2])
  // const ptPathUrl = translations && generatePath(translations[3])
  const author = content._rawAuthor
  const image = content._rawFeaturedImage
  const richText = content._rawRichText
  const link = content.market === "en-us" ? "blog" : `${content.market}/blog`
  const video = findAvailableModule(richText, "video")
  const { _updatedAt } = data.sanityBlogPost
  const steps = [
    {
      link,
      title: "Blog",
    },
    {
      link: `${link}/${content.category.slug.current}`,
      title: content.category.title,
    },
  ]

  // covers a case when custom cta was there, but no longer has any content - object contains type, but no other keys
  const isCtaAvailable = cta?._rawCta?.title || cta?._rawCta?.text

  // is going to be used for social share (window object can not be used directly in Gatsby but now available with browser-monads library)
  const url = window.location.href

  const printPage = useCallback(() => {
    window.print();
  }, []);

  return (
    <Layout
      translations={translations}
      enPathUrl={enPathUrl}
      esPathUrl={esPathUrl}
      // auPathUrl={auPathUrl}
      // ptPathUrl={ptPathUrl}
    >
      <Seo
        title={content.title}
        isBlogPost={true}
        author={author?.name}
        image={image?.asset.url}
        path={pageContext.pagePath}
        datePublished={content.publishDate}
        dateModified={_updatedAt}
        breadcrumbs={steps}
        video={video}
        {...seo}
      />
      <Breadcrumbs homePageLabel={t("blog")} />
      <Section>
        <h1 className={styles.title}>{content.title}</h1>
      </Section>
      <BlogSocialShare title={content.title} url={url} />
      <div className="grid">
        <div className="gridItem large-3">
          <Sidebar
            author={content._rawAuthor?.name || "Open LMS"}
            image={content._rawFeaturedImage}
            publishDate={content.publishDate}
            categories={categories}
            form={form}
          />
        </div>
        <div className="gridItem large-9 large-offset-4">
          <main>
            <RichText
              blocks={content._rawRichText}
              enableAutoToc={content?.enableAutoToc}
              autoTocTitle={content?.autoTocTitle}
            />
            {author?.name?.toLowerCase() !== "open lms" && <Author {...author} />}
            <button className={cx("btn btn-xl btn-gradient mb-l mt-g", styles.downloadBtn)} onClick={printPage}>Download article</button>
          </main>
        </div>
      </div>
      {cta?.useEduAndWork && !isCtaAvailable ? (
        <Section background="grey">
          <CtaEduWork {...listing._rawCtaEduWork} />
        </Section>
      ) : null}
      {isCtaAvailable ? (
        <Section background="gradient">
          <Cta {...cta?._rawCta} />
        </Section>
      ) : null}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!, $market: String!, $_id: String!, $headerId: String!) {
    sanityBlogPost(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      _id
      _updatedAt(formatString: "MMMM Do, YYYY")
      tabs {
        content {
          title
          market
          _rawAuthor(resolveReferences: { maxDepth: 10 })
          _rawRichText(resolveReferences: { maxDepth: 20 })
          _rawFeaturedImage(resolveReferences: { maxDepth: 10 })
          publishDate(formatString: "MMMM Do, YYYY")
          category {
            title
            slug {
              current
            }
          }
          enableAutoToc
          autoTocTitle
        }
        cta {
          useEduAndWork
          _rawCta
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    allSanityBlogCategory(filter: { market: { eq: $market }, title: { ne: "ethink" } }) {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
    sanityBlogListing(_id: { regex: $headerId }) {
      tabs {
        content {
          _rawCtaEduWork
          subscribeForm {
            link
            title
          }
        }
      }
    }
    sanityTranslationBlogPost(translation: { elemMatch: { tabs: { content: {} }, _id: { eq: $_id } } }) {
      translation {
        _id
        _type
        tabs {
          content {
            market
            slug {
              current
            }
            category {
              id
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPost
