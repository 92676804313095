import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import upperFirst from "lodash/upperFirst"

import Image from "../image/image"
import Modal from "../modal/modal"
import Categories from "../categories/categories"
import Form from "../form/form"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./sidebar.module.scss"

const Sidebar = ({ author, image, publishDate, categories, form, entity, formEvent, isFormDisabled, description }) => {
  const breakpoints = useBreakpoint()
  const { t } = useTranslation();
  const isMobile = breakpoints.large

  const renderForm = () => {
    if (isFormDisabled || !form?.link) return <br />
    if (isMobile)
      return (
        <Modal isAuto modalClassName={styles.modalBody} modalContent={<Form {...form} />}>
          <button className={classNames("btn btn-gradient btn-xl", styles.modalButton)}>{formEvent}</button>
        </Modal>
      )
    return (
      <div className={styles.subscribe}>
        <h3 className={styles.sectionTitle}>{`${formEvent}`}: </h3>
        <Form {...form} />
      </div>
    )
  }

  return (
    <aside className={styles.wrapper}>
      {image && <Image image={image.asset} alt={image.alt} className={styles.image} />}
      <div className={styles.content}>
        {author && <p className={styles.sectionTitle}>{upperFirst(t("by"))} {author}</p>}
        <p>{publishDate}</p>
        <div>{description}</div>
        <h3 className={styles.sectionTitle}>{upperFirst(t("categories"))}: </h3>
        {categories && <Categories categories={categories} link={entity} sidebar />}
        {renderForm()}
      </div>
    </aside>
  )
}

Sidebar.propTypes = {
  author: PropTypes.string,
  image: PropTypes.object,
  categories: PropTypes.object,
  publishDate: PropTypes.string,
  form: PropTypes.object,
  entity: PropTypes.string,
  formEvent: PropTypes.string,
  isFormDisabled: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

Sidebar.defaultProps = {
  author: "",
  image: null,
  categories: null,
  publishDate: "",
  entity: "blog",
  formEvent: "Subscribe",
  form: null,
  isFormDisabled: false,
  description: "",
}

export default Sidebar
