import React from "react"
import PropTypes from "prop-types"
import upperFirst from "lodash/upperFirst"

import SocialIcon from "../socialIcon/socialIcon"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./socialMedia.module.scss"

const SocialMedia = ({ list }) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.socialList}>
      {list.map(acc => (
        <li key={acc._key}>
          <a href={acc.url} target="_blank" rel="nofollow noopener noreferrer">
            <span className="visually-hidden">{upperFirst(t("link to"))} {acc.platform}</span>
            <SocialIcon type={acc.platform} />
          </a>
        </li>
      ))}
    </ul>
  )
}

SocialMedia.propTypes = {
  list: PropTypes.array.isRequired,
}

SocialMedia.defaultProps = {
  list: [],
}

export default SocialMedia
