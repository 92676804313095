import React from "react"

import SocialIcon from "../socialIcon/socialIcon"

import * as styles from "./blogPostSocialShare.module.scss"

const socialList = ["LinkedIn", "Twitter"]

const BlogSocialShare = ({ title, url }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
          target="_blank"
          rel="nofollow noopener noreferrer">
          <SocialIcon type={socialList[0]} />
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${url}&text=${title}`}
          target="_blank"
          rel="nofollow noopener noreferrer">
          <SocialIcon type={socialList[1]} />
        </a>
      </div>
    </div>
  )
}

export default BlogSocialShare
